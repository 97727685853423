import React from "react"
import Layout from "../components/layout"
import Page from "../components/page"
import Form from "../components/form"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import Masonry from 'react-masonry-component'
import { renderRichText } from "gatsby-source-contentful/rich-text"

const masonryOptions = {
    transitionDuration: 0
};

export const query = graphql`
    {
        quotes: allContentfulTestimonial(sort: {fields: id, order: DESC}) {
            nodes {
                id
                title
                content {
                    raw
                }
            }
        }
    }
`;

function Testimonials({ data }) {
    return (
        <Layout>
            <Seo
                title="Testimonials"
                description="Comments from our clients after participating in our theraphy sessions."
            />

            <Page pageTitle="Testimonials" pageClass="page-testimonials">
                <Masonry
                    className={'quote-list-wrap'}
                    options={masonryOptions}
                    disableImagesLoaded={true}>
                    {data.quotes.nodes.map(quote => (
                        <article className="quote-listing" key={quote.id}>
                            <span className="quote-icon">&rdquo;</span>
                            <div className="quote-text">{renderRichText(quote.content)}</div>
                            <h3>{quote.title}</h3>
                        </article>
                    ))}
                </Masonry>
            </Page>

            <Form />
        </Layout>
    );
}

export default Testimonials
